import WcCardTabs from '@components/shared/WcCardTabs'

const OrganizationTabs = {
  components: {
    WcCardTabs,
  },

  computed: {
    tabs() {
      return [
        {
          name: this.$t('activerecord.models.organization.one'),
          route: { name: 'manager-organizations-edit', params: { id: this.organization_id } },
        },
        {
          name: this.$t('activerecord.models.organization/profile.one'),
          route: {
            name: 'manager-organizations-profile-edit',
            params: { organization_id: this.organization_id },
          },
        },
        {
          name: this.$t('activerecord.models.contact.one'),
          route: {
            name: 'manager-organizations-contact-edit',
            params: { organization_id: this.organization_id },
          },
        },
        {
          name: this.$t('activerecord.models.address.one'),
          route: {
            name: 'manager-organizations-address-edit',
            params: { organization_id: this.organization_id },
          },
        },
        {
          name: this.$t('activerecord.models.machine.other'),
          route: {
            name: 'manager-organizations-machines',
            params: { organization_id: this.organization_id },
          },
        },
        {
          name: this.$t('activerecord.models.category.other'),
          route: {
            name: 'manager-organizations-categories-edit',
            params: { organization_id: this.organization_id },
          },
        },
        {
          name: this.$t('activerecord.models.role.other'),
          route: {
            name: 'manager-organizations-roles-edit',
            params: { organization_id: this.organization_id },
          },
        },
      ]
    },
  },
}

export default OrganizationTabs
