import api from '@services/api'

export default {
  get(axios, params, query, config) {
    return api.get(
      axios,
      '/manager/organizations/:organization_id/categories',
      params,
      query,
      config
    )
  },

  update(axios, params, payload, config) {
    return api.update(
      axios,
      '/manager/organizations/:organization_id/categories',
      params,
      payload,
      config
    )
  },

  searchCategories(axios, params, query, config) {
    return api.get(
      axios,
      '/manager/organizations/:organization_id/categories/search_categories',
      params,
      query,
      config
    )
  },
}
